function NavBar(props) {
    const TABS = [
        {
            title: "Home",
            href: "/",
        },
        // {
        //     title: "Testimonials",
        //     href: "/testimonials",
        // },
        {
            title: "Contact Us",
            href: "/contact-us",
        },
    ];
    const INACTIVE_CLASS = 'btn btn-outline-primary';
    const ACTIVE_CLASS = 'btn btn-outline-primary active';

    return (
        <div className="bg-light" style={{padding:'10px', marginBottom:'20px'}}>
            <a href="/" style={{textDecoration:'none'}}>
                <img src="/banner.png" alt="MouthClub banner" width="207" style={{marginRight:'60px'}} />
            </a>

            <div className="btn-group">
                {
                    TABS.map(t =>
                        (<a
                            href={t.href}
                            key={t.title}
                            name="navbar-group"
                            className={t.href===props.path ? ACTIVE_CLASS : INACTIVE_CLASS}
                        >
                            {t.title}
                        </a>)
                    )
                }
            </div>

            <a href={props.portalBaseUrl} target="_blank" rel="noreferrer" className="btn btn-outline-secondary" style={{float:'right'}}>
                Client Portal
            </a>
        </div>
    );
}

export default NavBar;