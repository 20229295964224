import React, { useState } from "react";
import { mcSubmitContactUsForm } from "../apiClient";

function ContactUs(props) {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const [error, setError] = useState(false);
    const [response, setResponse] = useState('');

    const submitForm = async () => {
        // TODO: add some basic input validation
        const res = await mcSubmitContactUsForm(name, email, message);
        if (res.is2xx) {
            setError(false);
            setResponse('Thank You For Contacting Us!')
        } else {
            setError(true);
            setResponse('An Error Occurred: ' + res.body);
        }
    };

    const resetForm = async () => {
        setName('');
        setEmail('');
        setMessage('');
    };

    const shouldShowForm = !response || error;

    return (
        <div style={styles.div}>
            <div className="col-8 offset-md-2">
                <p style={styles.p}>
                    Get In Touch With Us!
                </p>
            </div>

            {!!response && <div className="col-8 offset-md-2">
                <p style={error ? styles.error : styles.p}>
                    {response}
                </p>
            </div>}

            {shouldShowForm && <div>
                <div className="col-4 offset-md-4" style={styles.row}>
                    <input type="text" className="form-control" placeholder="Name" required size="50" value={name} onChange={evt => setName(evt.target.value)}/>
                </div>

                <div className="col-4 offset-md-4" style={styles.row}>
                    <input type="email" className="form-control" placeholder="Email address" required size="255" value={email} onChange={evt => setEmail(evt.target.value)} />
                </div>

                <div className="col-4 offset-md-4" style={styles.row}>
                    <textarea className="form-control" placeholder="Your message" required rows="10" cols="50" value={message} onChange={evt => setMessage(evt.target.value)}></textarea>
                </div>

                <div className="col-4 offset-md-4" style={styles.row}>
                    <button className="btn btn-primary" style={styles.rspacer} onClick={submitForm}>Submit</button>
                    <button className="btn btn-danger" style={styles.rspacer} onClick={resetForm}>Reset</button>
                </div>
            </div>}
        </div>
    );
}

const styles = {
    div: {
        marginBottom: 54,
    },
    p: {
        color: '#024e99',
        fontSize: '20px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
    error: {
        color: 'red',
        fontSize: '20px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
    row: {
        marginTop: '10px',
    },
    rspacer: {
        marginRight: '10px',
    },
};

export default ContactUs;