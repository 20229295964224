const {REACT_APP_API_BASE_URL, REACT_APP_CLIENT_ACCESS_TOKEN} = process.env;

const _encodePair = (pair) => {
    const [k, v] = pair;
    if (Array.isArray(v)) {
        return v.map(vv => encodeURIComponent(k) + '[]=' + encodeURIComponent(vv)).join('&')
    }
    return encodeURIComponent(k) + '=' + encodeURIComponent(v);
};

const _makeQueryString = (query = {}) => {
    let str = Object.entries(query).map(_encodePair).join('&'); // stupid
    if (str) {
        str = '?'+str;
    }
    return str;
};

const _fetch = async (url, options) => {
    const res = await fetch(url, options);
    const ret = {
        is2xx: res.status>=200 && res.status<=299,
        is3xx: res.status>=300 && res.status<=399,
        is4xx: res.status>=400 && res.status<=499,
        is5xx: res.status>=500 && res.status<=599,
        url: res.url,
        status: res.status,
        headers: res.headers.map,
        body: await res.text(),
    };

    try {
        ret.body = JSON.parse(ret.body);
    } catch (err) {
        if (err instanceof SyntaxError) {
            console.log('Response not JSON');
        }
    }

    return ret;
};

const _get = async (endpoint, query={}) => {
    const url = REACT_APP_API_BASE_URL + endpoint + _makeQueryString(query);
    const options = {
        method: 'GET',
        headers: {
            'x-mc-client-access-token': REACT_APP_CLIENT_ACCESS_TOKEN,
        },
    };
    return _fetch(url, options);
};

const _post = async (endpoint, body) => {
    const url = REACT_APP_API_BASE_URL + endpoint;
    const options = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'x-mc-client-access-token': REACT_APP_CLIENT_ACCESS_TOKEN,
        },
        body: JSON.stringify(body),
    };
    return _fetch(url, options);
};

export const mcSubmitContactUsForm = async (name, email_address, message) => {
    const body = {name, email_address, message};
    return _post('/contactUs', body);
};

export const mcGetFaqs = async () => {
    return _get('/faqs')
};

export const mcGetTestimonials = async () => {
    return _get('/testimonials');
};
