function Home(props) {
    return (
        <div style={styles.div}>
            <p style={styles.p}>
                MouthClub is an innovative dental marketing company with primary focus on increasing case acceptance and new referrals generation.
            </p>
        </div>
    );
}

const styles = {
    div: {
        marginTop: 10,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '55%',
    },
    p: {
        color: '#024e99',
        fontSize: '23px',
        fontFamily: 'Verdana, Geneva, Tahoma, sans-serif',
        textAlign: 'center',
    },
};

export default Home;