import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import ContactUs from "./pages/ContactUs";
import ErrorNotFound from "./pages/ErrorNotFound";

const PAGES = [
    '/',
    '/contact-us',
];

function App() {
    const path = window.location.pathname;

    const {REACT_APP_PORTAL_BASE_URL} = process.env;

    return (
        <div className="App">
            <NavBar path={path} portalBaseUrl={REACT_APP_PORTAL_BASE_URL} />

            {path==='/' && <Home />}

            {path==='/contact-us' && <ContactUs />}

            {!PAGES.includes(path) && <ErrorNotFound />}

            <Footer />
        </div>
    );
}

export default App;
